import {
  Box,
  Button,
  Container,
  Image,
  Link,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FaDiscord,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';

import { ReactNode } from 'react';

const Logo = (props: any) => {
  const imgSrc = useColorModeValue(
    '/images/logo_dark.png',
    '/images/COHEZION_LOGOS-03.png'
  );

  return <Image alt='COHEZION logo' src={imgSrc} width={'150px'} {...props} />;
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      target='_blank'
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
    // bg={useColorModeValue('gray.500', 'gray.900')}
    // color={useColorModeValue('gray.700', 'gray.200')}
    // marginTop={10}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={2}
        spacing={4}
        justify={'center'}
        align={'center'}
      >
        <Logo />
        <Stack direction={'row'} spacing={6}>
          <Link href={'/'} isExternal={false}>
            Home
          </Link>
          <Link href={'/legal/acceptable-use-policy'} isExternal={false}>
            Acceptable Use Policy
          </Link>
          <Link href={'/legal/privacy-policy'} isExternal={false}>
            Privacy Policy
          </Link>
          <Link href={'/legal/cookie-policy'} isExternal={false}>
            Cookie Policy
          </Link>
          <Link href={'/legal/disclaimer-policy'} isExternal={false}>
            Disclaimer Policy
          </Link>
          <Link
            href={
              'https://docs.google.com/forms/d/e/1FAIpQLSeONPTUHlpvFFvKBDXPuf4ycOkfc8qIfCXsSdiWMUGBiL2YoQ/viewform'
            }
            isExternal={true}
          >
            Opt Out
          </Link>
          <Link
            href={
              'https://docs.google.com/forms/d/e/1FAIpQLSchvoQIYxRWSOK2CjTMSpNt16B8Gbt-9kRGBQgMW4SRHz2H5w/viewform'
            }
            isExternal={true}
          >
            DSAR
          </Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.600', 'gray.700')}
      >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}
        >
          <Text>© 2024 COHEZION.ai All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton
              label={'Twitter'}
              href={'https://www.twitter.com/cohezion_ai'}
            >
              <FaXTwitter />
            </SocialButton>
            <SocialButton
              label={'LinkedIn'}
              href={'https://www.linkedin.com/company/91417480'}
            >
              <FaLinkedin />
            </SocialButton>
            <SocialButton
              label={'Discord'}
              href='https://discord.gg/j5XvZJJAsF'
            >
              <FaDiscord />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

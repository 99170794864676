import Rive, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

export default function AiBugAnim() {
  return (
    <Rive
      stateMachines={'State Machine 1'}
      src='/images/aibuganim_v2.riv'
      layout={
        new Layout({
          fit: Fit.FitWidth,
          alignment: Alignment.TopCenter,
          maxY: 500,
          maxX: 500,
        })
      }
    />
  );
}

import { Box, Center, HStack, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image'

export default function TrustedBy() {

  const logos = [
    // {
    //   src: '/images/trustedBy/DreamhavenText.svg',
    //   alt: 'Dreamhaven Logo',
    //   height: 350,
    //   width: 350,
    // },
    {
      src: '/images/trustedBy/rayLogo.svg',
      alt: 'Ray Browser logo',
      height: 100,
      width: 150,
    },
    {
      src: '/images/trustedBy/firestoke_logo.svg',
      alt: 'FireStoke Games Logo',
      height: 200,
      width: 200,
    },
    {
      src: '/images/trustedBy/shapez.png',
      alt: 'Shapes 2 Logo',
      height: 280,
      width: 280,
    },
    {
      src: '/images/trustedBy/onceHuman.png',
      alt: 'Once Human',
      height: 200,
      width: 200,
    },

    {
      src: '/images/trustedBy/igb.svg',
      alt: 'Indie Game Business',
      height: 250,
      width: 250,
    },
    {
      src: '/images/trustedBy/raftur_games.png',
      alt: 'Raftur Games',
      height: 140,
      width: 140,
    },

  ]


  return (
    <Center>
    <Box className='trusted-by-component' paddingBottom={20}  maxWidth={'8xl'} >
      <Center marginBottom={10}>
        <Text color={'#fff'} fontSize={'4xl'} fontWeight={'bold'}>
          Trusted By
        </Text>
      </Center>

      <SimpleGrid columns={{sm: 2, md: logos.length}} gap={5}>

        {logos.map((logo, index) => (
          <Center key={`key_${index}`}>
            <Image src={logo.src} alt={logo.alt} width={logo.width} height={logo.height} />
          </Center>
        ))}
      </SimpleGrid>
      </Box>
      </Center>
  );
}

import { Box, Center, Container, HStack, List, ListIcon, ListItem, SimpleGrid, Text, VStack } from '@chakra-ui/react';

import Footer from 'components/footer';
import LandingFeature from 'components/landingpage/features';
import LandingPageHero from 'components/landingpage/Hero';
import { NextSeo } from 'next-seo';
import TrustedBy from 'components/trusted-by/trusted-by';
import { LuClock12, LuClock3, LuClock7, LuClock9 } from "react-icons/lu";

const types = [
  {
    title: 'Community Analytics',
    copy: 'Gain understanding across bug reports, feedback and community generated content to extrapolate actionable insights that directly impact player LTV, engagement and prioritization of development efforts.',
    image: 'CommunityAnalytics.png',
    bullets: [],
    status: 'In Development',
  },
  {
    title: 'AI-Guided Bug Reporting Workflow',
    copy: 'Say goodbye to spreadsheets, complex forms, incomplete reports and copying and pasting. Leverage the power of AI to streamline and centralize your bug reporting workflows.',
    // image: 'bugNew.png',
    animBlock: 'aibug',
    // bullets: ['Where your users already are'],
    status: 'Released',
  },
  {
    title: 'AI-Guided Feedback Workflow',
    copy: 'Understand the “why” behind community suggestions effortlessly. Eliminate back-and-forth communication. Harness the power of AI to streamline and centralize your feedback workflows.',
    image: 'Feedback.png',
    bullets: [],
    status: 'In Development',
  },
  {
    animBlock: 'test',
    title: 'Direct Project Management Integration',
    copy: 'Understand the “why” behind community suggestions effortlessly. Eliminate back-and-forth communication. Harness the power of AI to streamline and centralize your feedback workflows.',
    image: 'integrate.png',
    status: 'Released',
    bullets: [],
  },
];

const headerStyle = {
  paddingTop: '40px',
  boxShadow:
    'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
  backgroundImage:
    'radial-gradient(circle at center center, transparent 0%,rgb(55,39,94) 85%),linear-gradient(258deg, rgba(192, 192, 192,0.05) 0%, rgba(192, 192, 192,0.05) 50%,rgba(60, 60, 60,0.05) 50%, rgba(60, 60, 60,0.05) 100%),linear-gradient(407deg, rgba(97, 97, 97,0.05) 0%, rgba(97, 97, 97,0.05) 50%,rgba(52, 52, 52,0.05) 50%, rgba(52, 52, 52,0.05) 100%),linear-gradient(420deg, rgba(98, 98, 98,0.05) 0%, rgba(98, 98, 98,0.05) 50%,rgba(249, 249, 249,0.05) 50%, rgba(249, 249, 249,0.05) 100%),linear-gradient(367deg, rgba(1, 1, 1,0.05) 0%, rgba(1, 1, 1,0.05) 50%,rgba(202, 202, 202,0.05) 50%, rgba(202, 202, 202,0.05) 100%),linear-gradient(281deg, rgba(61, 61, 61,0.05) 0%, rgba(61, 61, 61,0.05) 50%,rgba(254, 254, 254,0.05) 50%, rgba(254, 254, 254,0.05) 100%),linear-gradient(356deg, rgba(237, 237, 237,0.05) 0%, rgba(237, 237, 237,0.05) 50%,rgba(147, 147, 147,0.05) 50%, rgba(147, 147, 147,0.05) 100%),linear-gradient(484deg, rgba(183, 183, 183,0.05) 0%, rgba(183, 183, 183,0.05) 50%,rgba(57, 57, 57,0.05) 50%, rgba(57, 57, 57,0.05) 100%),radial-gradient(circle at center center, rgb(55,39,94),rgb(55,39,94))',
};

const Home = () => {
  return (
    <>
      <NextSeo
        title='COHEZION'
        description='Community Data Platform Designed for Gaming Communities'
        canonical='https://cohezion.ai/'
        openGraph={{
          url: 'https://cohezion.ai/',
          title: 'COHEZION',
          description:
            'All-in-one community data aggregration platform designed for gaming communities',
          images: [
            {
              url: 'https://cohezion.ai/images/ez_avat.png',
              width: 800,
              height: 600,
              alt: 'cohezion avatar',
              type: 'image/png',
            },
          ],
          siteName: 'COEHZION',
        }}
      />

      <Box style={headerStyle} boxShadow='lg'>
        <LandingPageHero />
        <TrustedBy />
      </Box>

      <Box backgroundColor={'RussianViolet.400'} color={'SeaSalt.500'} paddingY={24}>
        <Container maxW={'8xl'} >
          <SimpleGrid gap={10} columns={{sm: 1, md: 2}}>
            <Box
              width={'100%'}
              fontSize={'4xl'}
              fontWeight={'bold'}
              background={'RaisnBlack.500'}
              textAlign={'center'}
              borderRadius={'xl'}>
              
              {/* <Box display="flex" justifyContent="center" alignItems="center"> */}
              <Center height={'100%'}>
                <VStack>
                  <Text>Product & operations teams waste</Text>
                  <Text
                    fontWeight={'bolder'}
                    background={'linear-gradient(to right, #A500D6 0%, #D857FF 100%)'}
                    backgroundClip={'text'} >
                    hundreds of hours...
                  </Text>
                  </VStack>
                </Center>
              {/* </Box> */}
                
            </Box>
            <Box fontSize={'2xl'}>
              <List spacing={5}>
                <ListItem>
                  <ListIcon as={LuClock12} color='SeaSalt.500' />
                  20 hours aggregating and triaging <Text as={'span'} fontWeight={'bold'}>bug reports</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={LuClock3} color='SeaSalt.500' />
                  20 hours aggregating and understanding <Text as={'span'} fontWeight={'bold'}>feedback</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={LuClock7} color='SeaSalt.500' />
                  100 hours analyzing <Text as={'span'} fontWeight={'bold'}>chat</Text>, <Text as={'span'} fontWeight={'bold'}>reviews,</Text> and <Text as={'span'} fontWeight={'bold'}>social media posts</Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={LuClock9} color='SeaSalt.500' />
                  5 hours writing <Text as={'span'} fontWeight={'bold'}>patch notes</Text>
                </ListItem>
              </List>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>

      {types.map((type, index) => (
        <LandingFeature
          key={`feat_${index}`}
          title={type.title}
          body={type.copy}
          bullets={type.bullets}
          image={type.image}
          status={type.status}
          animBlock={type.animBlock}
          isEven={index % 2 === 0}
        />
      ))}

      <Footer />
    </>
  );
};

export default Home;

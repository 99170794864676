import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import Rive, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

const LandingPageHero = () => {

  const highlightStyle = {
    'fontWeight': 'bolder',
    'background': 'linear-gradient(to right, #A500D6 0%, #D857FF 100%)',
    'backgroundClip': 'text'
  }

  return (
    <VStack
      align={'center'}
      spacing={{ base: 8, md: 10 }}
      direction={{ base: 'column', md: 'row' }}
      height={'1160'}
      maxW={{ base: 'sm', md: '4xl', lg: '8xl' }}
      margin={'0 auto'}
    >
      <Stack flex={1} spacing={{ base: 5, md: 5 }} color={'purple.200'}>
        <Heading lineHeight={1.1} fontWeight={'bold'} textAlign={'center'}>
          <Text
            as={'span'}
            position={'relative'}
            textShadow={'0 1px 1px rgba(0,0,0,0.4)'}
            zIndex={10}
            textAlign={'center'}
          >
            Community Data Platform Designed for Gaming Communities 
          </Text>
        </Heading>
        <Text
          fontSize={'2xl'}
          fontWeight={'bold'}
          color={'purple.100'}
          textAlign={'center'}
        >
          Increase <Text as={'span'}
            background={'linear-gradient(to right, #A500D6 0%, #D857FF 100%)'}
            backgroundClip={'text'} fontWeight={'bolder'}>Player LTV</Text> and <Text as={'span'}
              background={'linear-gradient(to right, #A500D6 0%, #D857FF 100%)'}
              backgroundClip={'text'} fontWeight={'bolder'}>Community Engagement</Text> Through Data-Driven Decisions
        </Text>
        <Stack
          direction={{ base: 'row', sm: 'row' }}
          width='100%'
          padding={0}
          textAlign={'center'}
        >
          <Container marginY={'10'}>
            <HStack gap={10}>
              <Box>
                <Button
                  colorScheme='green'
                  fontSize={'24px'}
                  lineHeight={'60px'}
                  height={'60px'}
                  borderRadius={'20'}
                  marginRight={10}
                  boxShadow={'3px 3px 10px rgba(0, 0, 0, 1)'}
                  width={'100%'}
                  // style={{ margin: '0 auto' }}
                  onClick={() => {
                    window.open('https://app.cohezion.ai/sign-up', '_blank');
                  }}
                >
                  30 Day Trial
                </Button>
                <Text fontSize={'sm'} marginTop={'15px'}> No Credit Card Required</Text>
              </Box>
              <button
                className='btn'
                style={{ marginBottom: '35px' }}
                onClick={() => {
                  window.open(
                    'https://calendar.google.com/calendar/u/0/appointments/AcZssZ30kwMH2usK38Aw_VRAwykd5xANTaLx8zME5b4=',
                    '_blank'
                  );
                }}
            >
                Schedule a Demo
            </button>
            </HStack>


          </Container>

        </Stack>
        <Container maxWidth={'8xl'} marginTop={20}>
          <div style={{ position: 'relative', paddingBottom: '0', height: '600px' }}>
            <iframe src="https://www.loom.com/embed/1b35f0a009a44bb291528a48a35e1911?sid=d8680347-4e25-4209-8162-dec3818976aa&hideEmbedTopBar=true"
              frameBorder="0"
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></iframe>
          </div>
        </Container>
      </Stack>
    </VStack>
  );
};

export default LandingPageHero;

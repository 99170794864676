import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Image,
  Stack,
  StackDivider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Rive, { Alignment, Fit, Layout } from '@rive-app/react-canvas';

import AiBugAnim from './ai-bug-anim/ai-bug-anim';
import { ReactElement } from 'react';

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
  color?: string;
}

const Feature = ({ text, icon, iconBg, color }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={4}
        h={4}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
        flexShrink={0}
        flexGrow={0}
      >
        {icon}
      </Flex>
      <Text fontWeight={600} color={color}>
        {text}
      </Text>
    </Stack>
  );
};

interface LandingFeatureProps {
  title: string;
  body: string;
  bullets?: string[];
  image?: string | null;
  status: string;
  animBlock?: 'block' | 'aibug' | string | null;
  isEven?: boolean;
}

export default function LandingFeature(props: LandingFeatureProps) {
  const { title, body, bullets, image, animBlock, isEven } = props;

  const iconBg = useColorModeValue('purple.400', 'purple.900');
  const dividerColor = useColorModeValue('gray.100', 'gray.700');
  const dividerColorEven = useColorModeValue('gray.400', 'gray.500');
  const headerColorEven = useColorModeValue('gray.800', 'gray.300');
  const headerColor = useColorModeValue('SeaSalt.500', 'gray.300');

  return (
    <Box _even={{ backgroundColor: 'RussianViolet.400' }}>
      <Container maxW={'8xl'} py={16}>
        <Flex
          className='landingFeature'
          gap={20}
          flexDirection={{ md: isEven ? 'row-reverse' : 'row', base: 'column' }}
        >
          <Stack
            // width={{ base: '100%', md: 'auto' }}
            marginTop={{ base: 0, md: 20 }}
            spacing={{ base: 0, md: 5 }}
          >
            <Heading
              color={'SeaSalt.500'}
              fontSize={'4xl'}
            >
              {title}
            </Heading>
            <Text
              color={'SeaSalt.600'}
              fontSize={'2xl'}
            >
              {body}
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={isEven ? dividerColor : dividerColorEven}
                />
              }
            >
              {bullets?.map((bullet, index) => (
                <Feature
                  key={`bullet_${index}`}
                  iconBg={iconBg}
                  text={bullet}
                />
              ))}
            </Stack>
          </Stack>
          <Box
            width={{ base: '500px' }}
            minWidth={{ base: '500px' }}
            minHeight={'300px'}
            // visibility={{ md: 'visible', base: 'hidden' }}
          >
            {image ? (
              <Image
                rounded={'xl'}
                alt={'feature image'}
                src={`/images/${image}`}
                width={'500px'}
                minWidth={'500px'}
                display={'block'}
              />
            ) : (
              <></>
            )}
            {animBlock === 'aibug' ? <AiBugAnim /> : <></>}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
